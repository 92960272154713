<template>
  <AuthLayout>
    <form class="grid grid-cols-1 gap-6" @submit.prevent="submit()">
      <div class="col-span-1" v-if="success || validationErrors.length > 0">
        <ValidationErrorMessage v-if="!success" :errors="validationErrors" />
        <Alert v-if="success" type="success">Je wachtwoord is succesvol gewijzigd!</Alert>
      </div>

      <template v-if="!success">
        <div class="col-span-1">
          <p class="text-xl text-prasset-green-500 font-semibold text-center">
            {{ $tuf('reset_your_password') }}
          </p>
        </div>

        <div class="col-span-1">
          <label for="password" class="text-sm text-prasset-green-800 mb-2 block">{{ $tuf('enter_a_new_password') }}</label>
          <PasswordField
            id="password"
            v-model="form.password"
            :required="true"
            name="password"
          />
        </div>
        <div class="col-span-1">
          <label for="rewordpassword" class="text-sm text-prasset-green-800 mb-2 block">{{ $tuf('repeat_the_new_password') }}</label>
          <PasswordField
            id="rewordpassword"
            v-model="form.password_confirmation"
            :required="true"
            name="password"
          />
        </div>
        <div class="col-span-1 flex flex-col items-center">
          <button
            class="button button--opague w-full max-w-xs"
            type="submit"
            @click="submit()"
          >
            {{ $tuf('set_password') }}
          </button>
        </div>
      </template>
    </form>
  </AuthLayout>
</template>

<script>
import Alert from '@/modules/core/views/components/Alert';
import Api from '@/providers/api';
import AuthLayout from '@/modules/auth/views/layouts/auth';
import PasswordField from '@/components/field/Password';
import ValidationErrorMessage from '@/modules/core/views/components/ValidationErrorMessage';

export default {
  name: 'Reset',

  components: {
    ValidationErrorMessage,
    AuthLayout,
    PasswordField,
    Alert,
  },

  data() {
    return {
      success: false,
      validationErrors: [],
      form: {
        password: '',
        password_confirmation: '',
      },
    };
  },

  methods: {
    submit() {
      Api()
        .post('/auth/password/reset', {
          ...this.form,
          token: this.$route.query.token,
        })
        .then(() => {
          this.form.email = '';
          this.success = true;
          setTimeout(() => {
            this.$router.push({ name: 'login' });
          }, 3000);
        })
        .catch(({ response }) => {
          const data = response.data;
          this.validationErrors = data.errors
            ? data.errors
            : { message: [data.message] };
        });
    },
  },
};
</script>
